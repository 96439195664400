import React from 'react';
import Head from 'next/head';

// This is a static page that will be pre-rendered at build time
export default function Custom404() {
  return (
    <>
      <Head>
        <title>404 - Page Not Found | Loob</title>
        <meta name="description" content="The page you're looking for cannot be found." />
      </Head>
      <div className="min-h-screen flex flex-col items-center justify-center" style={{ 
        backgroundColor: 'var(--background-primary, #0F0F0F)', 
        color: 'var(--text-primary, #FFFFFF)',
        fontFamily: 'var(--font-family-primary, "Comfortaa", "Nunito", "Varela Round", sans-serif)'
      }}>
        <div className="text-center px-4 py-8 rounded-lg glass-effect" style={{
          background: 'rgba(26, 26, 26, 0.7)',
          boxShadow: '0px 6px 16px 0px rgba(232, 180, 184, 0.15), 0px 4px 14px 0px rgba(232, 180, 184, 0.1), 0px 8px 10px 0px rgba(232, 180, 184, 0.05)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          maxWidth: '400px',
          width: '100%'
        }}>
          <h1 className="text-6xl font-bold mb-4" style={{ 
            background: 'linear-gradient(to right, #D4A5A5, #C7989F)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}>404</h1>
          <p className="text-xl mb-8" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            Oops! This page has wandered into the desert
          </p>
          <a 
            href="/" 
            className="px-6 py-2 rounded-full"
            style={{
              background: 'linear-gradient(to right, #E8B4B8, #FFE4E1)',
              color: '#111',
              fontWeight: '500',
              transition: '0.3s ease',
              textDecoration: 'none',
              display: 'inline-block'
            }}
          >
            Return to Camp
          </a>
        </div>
      </div>
    </>
  );
}

// Force static generation of this page
export const getStaticProps = () => {
  return {
    props: {},
  };
}; 